import { React, View, Text, SEO, CenterWrapper, StyleSheet, Image } from 'lib'
import { PageTitle, BeconPlusStore } from 'components'
import { Theme } from 'app'
import becon_plus from 'assets/becon+.png'

import PriceBanner from './PriceBanner'
import BeconInPress from '../_homepage/BeconInPress'

function BeconPlus() {
  return (
    <>
      <View style={styles.wrapper}>
        <SEO title={'Becon+'} description={'Becon+'}/>
        <CenterWrapper style={[styles.wrapper]} contentContainerStyle={styles.content}>
          <Image source={becon_plus} style={styles.image}/>
          <PageTitle style={{ alignItems: 'center', textAlign: 'center' }} location='BECON+' title='Be Confident, Everyday.'/>
          <Text variant={`p1 textCenter multiline`} text={`No-one knows your safety needs better than you\nso choose the plan that suits your lifestyle.`}/>
        </CenterWrapper>

        <PriceBanner/>

        <CenterWrapper style={[styles.wrapper, styles.userCommentWrapper]} contentContainerStyle={[styles.content]}>
          <Text variant={`h4 textCenter`} text={`“I do lots of walking, usually on my own, and it's great to have Becon to give me an extra boost of confidence.”`}/>
          <Text variant={`p1`}><b>Elmer</b>, Becon User</Text>
        </CenterWrapper>

        <BeconInPress/>

        <BeconPlusStore image='promotional'/>
      </View>
    </>
  )
}

export default BeconPlus

const styles = StyleSheet.createComponentStyleSheet({
  wrapper: {
    ...Theme.flex,
    flexDirection: 'column',
    marginTop: Theme.spacing(10),
    marginBottom: Theme.spacing(7),
    [Theme.media.down('mid')]: {
      marginTop: Theme.spacing(6),
      marginBottom: Theme.spacing(5),
    },
  },
  content: {
    maxWidth: 970,
    flexDirection: 'column',
    alignItems: 'center',
    gap: Theme.spacing(5),
    [Theme.media.down('xxlarge')]: {
      maxWidth: 770,
    },
    [Theme.media.down('mid')]: {
      maxWidth: 600,
    },
  },
  userCommentWrapper: {
    ...Theme.flex,
    ...Theme.paddingVertical(10),
    flexDirection: 'column',
    alignItems: 'center',
    background: Theme.colors.lightPurple,
    marginTop: Theme.spacing(0),
    marginBottom: Theme.spacing(0),
  },
  image: {
    // maxWidth: 970,
    width: '70%',
    height: '100%',
    objectFit: 'contain',
  },
  buttonWrapper: {
    marginTop: Theme.spacing(6),
    gap: Theme.spacing(2),
    [Theme.media.down('largeish')]: {
      ...Theme.center,
    },
    [Theme.media.down('tiny')]: {
      marginTop: Theme.spacing(2),
      flexDirection: 'column',
      ...Theme.center,
    },
  },

  pricesWrapper: {
    backgroundColor: Theme.colors.greyscale1,
  },
  listWrapper: {
    display: 'flex',
    alignItems: 'center',
    gap: Theme.spacing(3),
    [Theme.media.down('mid')]: {
      gap: Theme.spacing(2),
    },
    [Theme.media.is('tiny')]: {
      flexDirection: 'column',
      padding: `${Theme.spacing(3)}px`,
    },
    [Theme.media.down('tiny')]: {
      flexDirection: 'column',
      padding: `${Theme.spacing(2)}px`,
    },
  },
  cardWrapper: {
    maxWidth: 340,
    background: `linear-gradient(${Theme.colors.greyscale2}, ${Theme.colors.greyscale2}) padding-box, linear-gradient(to right, ${Theme.colors.grad1}, ${Theme.colors.star}) border-box`,
    borderRadius: 18,
    border: '4px solid transparent',
    padding: `${Theme.spacing(5)}px ${Theme.spacing(3)}px`,
    alignItems: 'center',
    [Theme.media.down('mid')]: {
      padding: `${Theme.spacing(3)}px ${Theme.spacing(1)}px`,
    },
    [Theme.media.is('tiny')]: {
      padding: `${Theme.spacing(3)}px`,
      width: '100%',
      maxWidth: 'initial',
      alignItems: 'flex-start',
    },
  },
  innerWrapper: {
    [Theme.media.is('tiny')]: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
  },
  descriptionText: {
    order: 2,
    [Theme.media.is('tiny')]: {
      order: 1,
      maxWidth: '70%',
      textAlign: 'left',
    },
  },
  priceWrapper: {
    order: 3,
    [Theme.media.is('tiny')]: {
      order: 2,
    },
  },
  grid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    gridTemplateRows: 'repeat(2, 1fr)',
    gridGap: Theme.spacing(5),

    [Theme.media.down('mid')]: {
      display: 'grid',
      gridTemplateColumns: 'repeat(2, 1fr)',
    },
    [Theme.media.down('small')]: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  gridContent: {
    display: 'flex',
    gap: Theme.spacing(3),
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },

  downloadWrapper: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: Theme.spacing(20),
    [Theme.media.down('largeish')]: {
      flexDirection: 'column',
      alignItems: 'center',
      marginTop: Theme.spacing(10),
    },
  },
  innerWrapper2: {
    display: 'flex',
    flexDirection: 'column',
    [Theme.media.down('largeish')]: {
      alignItems: 'center',
    },
  },
  appImage: {
    maxWidth: 400,
    width: '100%',
    [Theme.media.down('largeish')]: {
      marginTop: Theme.spacing(10),
    },
    [Theme.media.down('small')]: {
      marginTop: Theme.spacing(10),
    },
  },
  text: {
    flexGrow: 0,
    [Theme.media.down('largeish')]: {
      textAlign: 'center',
      alignItems: 'center',
    },
  },
})
