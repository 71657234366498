import { Theme } from 'app'
import { CheckIcon } from 'assets/icons'
import { AppStoreButton, PlayStoreButton } from 'components'
import checkIcon from '../../assets/arrow.png'
import { React, View, Text, StyleSheet, Button, useState, Image } from 'lib'

const plans = [
  {
    price: 0.00,
    billing: 'Automated safety for walking journeys',
    title: 'FREE',
    isPlus: false,
    benefits: [
      'Fully automated safety technology',
      'Protect your walking journeys',
      'Use Becon all over the world',
      'Customise your map with tap-and-go emojis',
      'Save up to 5 favourite destinations',
      'Add up to 10 Key Contacts',
    ],
  },
  {
    price: [4.99, 29.99],
    billing: 'Perfect protection for busy & active people',
    isPlus: true,
    title: 'BECON+',
    benefits: [
      'Fully automated safety technology',
      'Protect your walking journeys',
      'Use Becon all over the world',
      'Customise your map with tap-and-go emojis',
      'Save unlimited favourite destinations',
      'Add up to 50 Key Contacts',
      'Protect any journey or activity',
      'Personalise your safety settings',
      'Use ‘Just Go’ mode for journeys without a route or destination',
      'Share your live journeys with contacts',
      'Unlock advanced Key Contact features',
      'Live location tracking following an alert',
      'Ad-free',
    ],
  },
]

const PriceCard: React.FC<typeof plans[number]> = ({ title, benefits, price, billing, isPlus, plan, key }) => {
  return (
    <View style={[styles.cardWrapper, isPlus ? styles.cardWrapperBackgroundPlus : styles.cardWrapperBackground]} variant={'flex column'}>
      <Text variant={'h5 capitalize'} style={isPlus ? styles.titlePlus : styles.title} text={title}/>
      <View style={styles.innerWrapper}>
        <View variant={'marginVertical:3 marginBottom:4 flex column alignCenter'} style={styles.priceWrapper}>
          {isPlus ?
            <Text variant={`h4 capitalize textCenter`} text={`£${price[plan]}/${plan === 0 ? 'Mo' : 'Yr'}`}/> :
            <Text variant={`h4 capitalize textCenter`} text={`£0.00/${plan === 0 ? 'Mo' : 'Yr'}`}/>
          }

          <Text variant={`p5 capitalize textCenter marginTop:1`} style={{ fontWeight: 'bold' }} text={billing}/>
        </View>
        <View style={styles.benefitsWrapper}>
          {benefits.map((benefit, key) => (
            <View style={styles.benefit} key={benefit}>
              <CheckIcon size={24} color={Theme.colors.grad1} style={{ flexShrink: 0 }}/>
              <Text variant={[`p3`, (key > 3 && isPlus) && 'bold']} style={styles.benefitsText} text={benefit}/>
            </View>
          ))}
        </View>
      </View>
    </View>
  )
}

function PriceBanner() {
  const [plan, setPlan] = useState(1)

  return (
    <View style={styles.pricesWrapper} variant={'flex column alignCenter marginVertical:5'}>
      <View style={styles.planContainer}>
        <View variant={'flex'} style={styles.plansWrapper}>
          <Button
            style={[styles.planButton, plan === 0 ? styles.planButtonActive : styles.planButtonDefault]}
            variant={plan === 0 ? ['white', 'responsive', 'gradient'] : ['responsive', 'noHover']}
            text={`Monthly`}
            onPress={() => setPlan(0)}
          />
          <View variant={'relative'} style={styles.yearButtonWrapper}>
            <View style={styles.label}>
              <Text variant={'p4'} text={'Yearly subscribers save 50%'}/>
              <Image
                style={styles.arrow}
                source={checkIcon}
                alt='arrow down'
              />
            </View>
            <Button
              style={[styles.planButton, plan === 1 ? styles.planButtonActive : styles.planButtonDefault]}
              variant={plan === 1 ? ['white', 'responsive', 'gradient'] : ['responsive', 'noHover']}
              text={`Yearly`}
              onPress={() => setPlan(1)}
            />
          </View>
        </View>
      </View>

      <View
        style={styles.listWrapper}
        variant={'fullWidth flex row'}
      >
        {plans.map((props, key) => (
          <PriceCard {...props} plan={plan} key={key}/>
        ))}
      </View>

      <View variant={['flexNoFill']} style={styles.buttonWrapper}>
        <AppStoreButton/>
        <PlayStoreButton/>
      </View>
    </View>
  )
}

const styles = StyleSheet.createComponentStyleSheet({
  pricesWrapper: {},
  planButton: {
    [Theme.media.down('mid')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  yearButtonWrapper: {
    [Theme.media.down('mid')]: {
      width: '100%',
    },
  },
  planContainer: {
    [Theme.media.down('mid')]: {
      width: '100%',
      paddingLeft: Theme.spacing(4),
      paddingRight: Theme.spacing(4),
    },
  },
  plansWrapper: {
    borderRadius: Theme.values.borderRadius - 2,
    background: Theme.colors.greyscale6,
  },
  title: {
    color: Theme.colors.grad1,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    display: 'inline-block',
  },
  titlePlus: {
    color: Theme.colors.grad1,
    background: Theme.colors.gradientLinear,
    textDecorationColor: Theme.colors.gradientLinear,
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    WebkitTextFillColor: 'transparent',
    display: 'inline-block',
  },
  planButtonActive: {
    borderRadius: Theme.values.borderRadius - 2,
    background: Theme.colors.gradientFollo,
  },
  planButtonDefault: {
    borderRadius: Theme.values.borderRadius - 2,
    background: Theme.colors.greyscale6,
  },
  listWrapper: {
    ...Theme.paddingHorizontal(4),
    display: 'flex',
    // alignItems: 'center',
    gap: Theme.spacing(3),
    paddingTop: Theme.spacing(4),
    paddingBottom: Theme.spacing(2),
    [Theme.media.up('mid')]: {
      justifyContent: 'center',
    },
    [Theme.media.down('mid')]: {
      overflowX: 'scroll',
      gap: Theme.spacing(2),
    },
  },
  buttonWrapper: {
    marginTop: Theme.spacing(2),
    gap: Theme.spacing(2),
    [Theme.media.down('largeish')]: {
      ...Theme.center,
    },
    [Theme.media.down('tiny')]: {
      marginTop: Theme.spacing(2),
      flexDirection: 'column',
      ...Theme.center,
    },
  },
  cardWrapper: {
    maxWidth: 468,
    minWidth: 300,
    minHeight: 800,
    borderRadius: 18,
    border: '4px solid transparent',
    padding: `${Theme.spacing(5)}px ${Theme.spacing(3)}px`,
    alignItems: 'center',
    [Theme.media.down('xxlarge')]: {
      maxWidth: 400,
    },
    [Theme.media.down('small')]: {
      padding: `${Theme.spacing(3)}px ${Theme.spacing(1)}px`,
      width: '100%',
    },
    [Theme.media.is('tiny')]: {
      padding: `${Theme.spacing(3)}px`,
      width: '100%',
      maxWidth: 'initial',
    },
  },
  cardWrapperBackgroundPlus: {
    background: `linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(to right, ${Theme.colors.grad1}, ${Theme.colors.star}) border-box`,
  },
  cardWrapperBackground: {
    background: `linear-gradient(#FFFFFF, #FFFFFF) padding-box, linear-gradient(to right, ${Theme.colors.greyscale12}, ${Theme.colors.greyscale13}) border-box`,
  },
  label: {
    position: 'absolute',
    width: 150,
    top: -65,
    right: 0,
    display: 'flex',
    textAlign: 'center',
    [Theme.media.up('xlarge')]: {
      width: 170,
      top: -70,
    },
    [Theme.media.down('mid')]: {
      right: 0,
    },
  },
  arrow: {
    position: 'absolute',
    top: Theme.spacing(3.25),
    right: Theme.spacing(1.5),
    [Theme.media.up('xlarge')]: {
      top: Theme.spacing(4.25),
    },
  },
  learnMore: {
    borderRadius: Theme.spacing(2.25),
  },
  innerWrapper: {
    height: '100%',
  },
  benefitsWrapper: {
    flex: 1,
  },
  benefit: {
    display: 'flex',
    gap: Theme.spacing(1),
  },
  benefitsText: {
    order: 2,
    marginBottom: Theme.spacing(2.5),
    [Theme.media.is('tiny')]: {
      order: 1,
      maxWidth: '70%',
      textAlign: 'left',
    },
  },
  priceWrapper: {
    order: 3,
  },
})

export default PriceBanner
